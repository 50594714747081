import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";

import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { AccessAlarmOutlined, DateRangeRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";

const TaskCard = ({ item, handleItemClick }) => {
  const avatarLetter = item.name.charAt(0).toUpperCase(); // Extracting first letter of the name for avatar

  const currentDate = new Date();
  const assignedDate = new Date(item.assignedon);
  const [assignedDateString, assignedTimeString] = item.assignedon?.split(" ");

  let tag = "";
if (assignedDate.toDateString() === currentDate.toDateString()) {
    tag = "Today";
  } else {
    tag = "Old";
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px #ffffff`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.0s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(1)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <Card
      sx={{
        margin: "5px",
        backgroundColor: "#ffffff",
        borderRadius: "15px",
        transition: "background-color 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
      }}
      onClick={() => handleItemClick(item)}
    >
      <CardContent
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column", // Display content vertically
          padding: "10px !important",
        }}
      >
     

        <Box sx={{ display: "flex", flexDirection: "row", mb: "10px" }}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar sx={{ bgcolor: "#000000", color: "#ffffff" }}>
              {avatarLetter}
            </Avatar>
          </StyledBadge>

          <Box sx={{ display: "flex", flexDirection: "column", mx: "10px" }}>
            <Typography
              variant="body2"
              sx={{
                mb: "5px",
                textAlign: "start",
                width: "100%",
                justifyContent: "center",
                fontSize: "14px",
                fontWeight: "bold",
                whiteSpace: 'nowrap'
              }}
              color="#000000"
            >
              {item.name}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                textAlign: "start",
                width: "100%",
                justifyContent: "center",
                fontSize: "12px",
                color: "#666666",
              }}
            >
              {item.mobile}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            flex: "70%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Date */}
          <Box sx={{ p: "0px", display: "flex", alignItems: "center" }}>
            <DateRangeRounded
              sx={{
                fontSize: "16px",
                color: "#000000",
                marginRight: "5px",
              }}
            />
               
            <Typography
              variant="date"
              sx={{
                my: "5px",
                textAlign: "start", // Align text center
                width: "100%",
                justifyContent: "center",
              }}
              color="#000000"
            >
               Assigned On:{assignedDateString}
            </Typography>
          </Box>
          {/* Time */}
          <Box sx={{ p: "0px", display: "flex", alignItems: "center" }}>
            <AccessAlarmOutlined
              sx={{
                fontSize: "16px",
                color: "#000000",
                marginRight: "5px",
              }}
            />
            <Typography
              variant="date"
              sx={{
                my: "5px",
                textAlign: "start", // Align text center
                width: "100%",
                justifyContent: "center",
              }}
              color="#000000"
            >
              Assigned At: {assignedTimeString}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaskCard;
