import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../components/theme";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/header";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { useEffect, useState } from "react";
import {
  AccountCircleRounded,
  AlarmRounded,
  ArrowBackRounded,
  AssignmentRounded,
  EmailRounded,
  PhoneForwardedRounded,
  PhoneIphoneRounded,
  SpeedRounded,
  TtyRounded,
} from "@mui/icons-material";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { GetReportByKey } from "../../data/repos/userRepo";
import { parse } from 'date-fns';

const ReportView = ({userId}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [responseData, setResponsedata] = useState([]);
  const [clickedLeadInfo, setClickedLeadInfo] = useState({});
  const navigate = useNavigate();
  const { key, startDate, endDate } = useParams();
  const staffId = JSON.parse(localStorage.getItem("loginInfo")).id;

  const columnData = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "source",
      headerName: "Source",
      flex: 0.25,
    },
    {
      field: "mobile",
      headerName: "Phone",
      flex: 0.5,
    },
  
    {
      field: "createdon",
      headerName: "Date",
      flex: 0.5,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.5,
    },
    {
      field: "possibility",
      headerName: "Possibility",
      flex: 0.5,
    },
    {
      field: "statusname",
      headerName: "Call Status",
      flex: 0.6,
    },
   
    
  ];
  const startDateString = startDate.toString();
  const endDateString = endDate.toString();

  // Split the date string by '-'
  const parts = startDateString.split('-');
  const endparts = endDateString.split('-');
  
  // Rearrange the parts to form a date string in dd/mm/yyyy format
  const formattedStartDateString = parts[0] + '/' + parts[1] + '/' + parts[2];
  const formattedEndDateString = endparts[0] + '/' + endparts[1] + '/' + endparts[2];
  
  console.log(formattedStartDateString);

  console.log("Date Reformated: ",formattedStartDateString, formattedEndDateString)
  useEffect(() => {

  const params = {
        startdate: formattedStartDateString,
        enddate: formattedEndDateString,
        filter: staffId,
        status: key
    }
    console.log("parms formed: ",params)
    getReportDataMutation.mutate(params);
  }, []);

  const handleGridItemClick = (params) => {
    // Get the ID of the clicked row from the params
    const clickedItemId = params.row.id;
    
    // Do something with the clicked item ID, such as passing it to a function
    console.log('Clicked item ID:', clickedItemId);
    navigate(`/u/${userId}/task/lead/${clickedItemId}`);
  };

  const handleBoxClick = (params) => {};

  const getReportDataMutation = useMutation(GetReportByKey, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setResponsedata(data?.responsedata);
      toast.success(data.responsedescription);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box m="10px 20px">
      <Box>
        <Box sx={{ p: "20px", display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handleGoBack}
            sx={{ color: "#000000", mr: "15px" }}
          >
            {<ArrowBackRounded />}
          </IconButton>
         
          
          <Typography
            variant="h5"
            fontWeight="bold"
            color={colors.textcolor[100]}
          >
            {"Report"}
          </Typography>

          
        </Box>

        {/* <Box m="0px 1px" display="flex" width="100%" height="100%">
              <FactCard
                title="Lead Status"
                value={leadStatus}
                bgColor="#B39DDB"
                textColor="#311B92"
                icon={<TtyRounded />}
              />

              <FactCard
                title="Assigned On"
                value={assignedOn}
                bgColor="#80CBC4"
                textColor="#004D40"
                icon={<AssignmentRounded />}
              />
              <FactCard
                title="Follow Up Count"
                value={followupCount}
                bgColor="#E6EE9C"
                textColor="#827717"
                icon={<PhoneForwardedRounded />}
              />
              <FactCard
                title="Is Call Scheduled?"
                value={callScheduled}
                bgColor="#FFCC80"
                textColor="#E65100"
                icon={<AlarmRounded />}
              />
            </Box> */}
      </Box>
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: colors.bg[100],
            cursor: 'pointer',
          },
          "& .name-column--cell": {
            color: colors.bg[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.bg[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.bg[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
        rows={responseData || []} 
        columns={columnData} 
        onCellClick={handleGridItemClick}/>
      </Box>
    </Box>
  );
};
export default ReportView;
// onClick={() => handleBoxClick(id)}
// style={{
//   cursor: "pointer",
//   display: "flex",
//   alignItems: "center",
// }}