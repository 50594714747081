import { Routes, Route, useParams } from "react-router-dom";
import SideBar from "./sideBar";
import Job from "../pages/job";
import UserDetails from "../pages/job/userDetails";
import PerfomanceDashboard from "../pages/leads/perfomanceDashboard";
import ReportView from "../pages/leads/ReportView";
import CreateLead from "../pages/leads/CreateLead";
import AttandenceDashboard from "../pages/staffs/AttandenceDashboard";
import ReportGeneration from "../pages/reports/ReportGeneration";
import Dashboard from "../pages/dashboard";

function Home() {
  const { userId } = useParams();
  return (
    <div className="app">
      <SideBar userId={userId} />
      <main className="content" style={{ flex: 1, overflowY: "auto" }}>
        <div className="container">
         
          <Routes>
          <Route path="/dashboard" element={<Dashboard userId={userId} />} />
            <Route path="/task" element={<Job userId={userId} />} />
            <Route path="/task/lead/:id" element={<UserDetails />} />
            <Route path="/addlead" element={<CreateLead />} />
            <Route path="/perfomance" element={<PerfomanceDashboard userId={userId} />} />
            <Route path="/perfomance/report/:key/:startDate/:endDate" element={<ReportView userId={userId} />} />
            <Route path="/report" element={<ReportGeneration userId={userId} />} />
            <Route path="/attandence" element={<AttandenceDashboard userId={userId} />} />
            {/* <Route path="/staffs" element={<Staffs />} /> */}
          </Routes>
        </div>
      </main>
    </div>
  );
}
export default Home;
