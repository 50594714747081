import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../components/header";
import { tokens } from "../../components/theme";
import { DataGrid } from "@mui/x-data-grid";

import { QueryClient, useMutation, useQuery } from "react-query";

import { GetStaffs } from "../../data/repos/userRepo";
import { GetCallRecordByStaff } from "../../data/repos/callRepo";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { formatCallerNumber, formatStudentName, secondsToHms } from "../../utls/commons";
import { CallMadeRounded, CallReceived, CallReceivedRounded } from "@mui/icons-material";

const CallStatus = () => {
  const theme = useTheme();
  const [responseData, setResponsedata] = useState([]);
  const [staffResponseData, setStaffResponseData] = useState([]);
  const [leadUpdateResponse, setLeadUpdateResponse] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const loginInfoMobile = JSON.parse(localStorage.getItem("loginInfo")).mobile
    const updateRequestObj = {
      mobile: loginInfoMobile
    }
    console.log("logged in mobileNumber is:", updateRequestObj)
    callRecordRequest.mutate(updateRequestObj);
  }, []);

  const formattedData = responseData.map((item) => ({
    ...item,
    // createdon: DateTimeFormatter(item.createdon),
    // lastupdatedon: DateTimeFormatter(item.lastupdatedon),
    conversationDuration: secondsToHms(item.conversationDuration),
    student: formatStudentName(item.studentname),
    called_number: formatCallerNumber(item),
  }));

  //   const handleBoxClick = (leadId) => {
  //     const clickedLeadInfo = responseData.find((data) => data.id === leadId);
  //     // if (clickedLeadInfo.status === 1) {
  //     setSelectedLead(clickedLeadInfo);
  //     console.log("clicked item ", clickedLeadInfo);
  //     setOpenDialog(true);
  //     // }
  //   };

  //   const handleCloseDialog = () => {
  //     console.log("Closing Dialog");
  //     setOpenDialog(false);
  //     setSelectedLead(null);
  //   };

  //   const handleUpdateStatus = (staffId, leadId, leadstate) => {
  //     console.log("handleUpdateStatus Called:", staffId);
  //     var requestData;
  //     if (leadstate === 6) {
  //       requestData = {
  //         staffid: staffId,
  //         leadid: leadId,
  //         status: 3,
  //         isgivenuplead: 1,
  //       };
  //     } else {
  //       requestData = {
  //         staffid: staffId,
  //         leadid: leadId,
  //         status: 3,
  //         isgivenuplead: 0,
  //       };
  //     }
  //     console.log("lead allocation request:", requestData);
  //     mutation.mutate(requestData);
  //     setResponsedata((prevData) =>
  //       prevData.map((data) => {
  //         if (data.id === leadId) {
  //           const updatedData = { ...data, status: 3, statusname: "Allocated" };
  //           console.log("updatedData:", updatedData);
  //           // updateLead(updatedData);
  //           return updatedData;
  //         }
  //         return data;
  //       })
  //     );
  //   };

  const columnData = [
    {
      field: "student",
      headerName: "Student",
      flex: 1,
    },
    {
      field: "called_number",
      headerName: "Student Number",
      flex: 1,
    },
    {
      field: "calltype",
      headerName: "Call Type",
      flex: 1,
      renderCell: ({ row: { calltype } }) => {
        let icon;
        let color;
      
        if (calltype === "Incoming") {
          icon = <CallReceivedRounded style={{ color: "#4caf50" }} />; // Blue color for income
          color = "#4caf50";
        } else if (calltype === "Outgoing") {
          icon = <CallMadeRounded style={{ color: "#3f51b5" }} />; // Green color for outgoing
          color = "#3f51b5";
        }
      
        return (
          <Box
            display="flex"
            width="100%"
            m="0 auto"
            px="10px"
            py="5px"
            justifyContent="center"
            borderRadius="25px"
            style={{ cursor: "pointer", color }}
            // onClick={() => handleBoxClick(id)} // Uncomment and define handleBoxClick if needed
          >
            <Box
              display="flex"
              alignItems="center"
            >
              {icon}
              <Typography variant="h6" sx={{ ml: "5px", color }}>
                {calltype}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    
    {
      field: "callDate",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "conversationDuration",
      headerName: "Duration",
      flex: 0.75,
    },
    {
      field: "callStatus",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row: { callStatus } }) => {
        let statusDisplay;
        let backgroundColor;
    
        switch (callStatus) {
          case "ANSWERED":
            statusDisplay = "ANSWERED";
            backgroundColor = "#4caf50";
            break;
          case "NOANSWER":
            statusDisplay = "NO ANSWER";
            backgroundColor = "#ff9800";
            break;
          case "CANCEL":
            statusDisplay = "CANCEL";
            backgroundColor = "#607d8b";
            break;
          case "Not Connected":
            statusDisplay = "Not Connected"; // Display "Missed call" for "Not Connected"
            backgroundColor = "#f44336";
            break;
          case "Connected":
            statusDisplay = "CONNECTED";
            backgroundColor = "#3f51b5";
            break;
          case "BUSY":
            statusDisplay = "BUSY";
            backgroundColor = "#ffc107";
            break;
          case "CHANUNAVAIL":
            statusDisplay = "UNAVAILABLE";
            backgroundColor = "#795548";
            break;
            case "Missed Call":
              statusDisplay = "Missed Call";
              backgroundColor = "#f44336";
              break;
          default:
            statusDisplay = callStatus; // Fallback to callStatus if not matched
            backgroundColor = "#f44336";
            break;
        }
        return (
          <Box
            display="flex"
            width="100%"
            m="0 auto"
            px="10px"
            py="5px"
            justifyContent="center"
            borderRadius="25px"
            backgroundColor={
              callStatus === "ANSWERED"
                ? "#4caf50"
                : callStatus === "NOANSWER"
                ? "#ff9800"
                : callStatus === "CANCEL"
                ? "#607d8b" //colors.redAccent[700]
                : callStatus === "Not Connected"
                ? "#f44336"
                : callStatus === "Connected"
                ? "#3f51b5"
                : callStatus === "BUSY"
                ? "#ffc107"
                : callStatus === "CHANUNAVAIL"
                ? "#795548"
                : "#f44336"
            }
            // onClick={() => handleBoxClick(id)}
            // style={{ cursor: "pointer" }}
          >
            <div
              //   onClick={() => handleBoxClick(id)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" color="#ffffff" sx={{ ml: "5px" }}>
                {statusDisplay}
              </Typography>
            </div>
          </Box>
        );
      },
    },
    {
      field: "recording_URL",
      headerName: "Recording",
      flex: 1.75,
      renderCell: ({ row }) => {
        const { recording_URL, conversationDuration } = row;
        // Check if the duration is less than 1 second
        if (convertToSeconds(conversationDuration) < 1) {
          return  <div style={{ textAlign: 'center', width: '100%' }}>No Recording Available</div>; // Display text if duration is less than 1 second
        }
        return <AudioPlayer url={recording_URL} />; // Display AudioPlayer if duration is 1 second or more
      },
      // renderCell: ({ row }) => {
      //   const { recording_URL } = row;
      //   return <AudioPlayer url={recording_URL} />;
      // },
    },
   
    {
      field: "callStartTime",
      headerName: "Call Start At",
      flex: 1,
    },
  ];

  const convertToSeconds = (time) => {
    const parts = time.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const callRecordRequest = useMutation(GetCallRecordByStaff, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setResponsedata(data?.responsedata);
      console.log("Recordings list:", data);
    },
    onError: (error) => {
      console.error("Error:", error.message);
    },
  });

  // const { data, isLoading, isError } = useQuery(
  //   "myData",
  //   () => GetCallRecordByStaff(),
  //   {
  //     onSuccess: (data) => {
  //       setResponsedata(data?.responsedata);
  //     },
  //     onError: (error) => {
  //       // Handle errors here
  //       console.error("Error:", error.message);
  //     },
  //   }
  // );

  const { staffData, isStaffsLoading, isStaffError } = useQuery(
    "staffData",
    GetStaffs,
    {
      onSuccess: (staffData) => {
        setStaffResponseData(staffData?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  //   const mutation = useMutation((requestData) => UpdateLeadData(requestData), {
  //     onSuccess: (data, variables, context) => {
  //       setLeadUpdateResponse(data?.responseData);
  //       // Optionally, trigger a refetch of data here
  //       queryClient.invalidateQueries("myData");
  //     },
  //     onError: (error) => {
  //       // Handle errors here
  //       console.error("Error:", error.message);
  //     },
  //   });

  //   const handleGridItemClick = (params) => {
  //     const clickedItemId = params.row.id;
  //     console.log("Clicked item ID:", clickedItemId);
  //     navigate(`/u/${userId}/task/lead/${clickedItemId}`);
  //   };
  function AudioPlayer({ url }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(null);

    const handlePlay = () => {
      if (audioElement && audioElement !== document.activeElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
      setIsPlaying(true);
      setAudioElement(document.activeElement);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    return (
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        p="8px 10px" // Adjusted padding to give more space
      >
        <audio
          controls
          style={{ width: "100%" }}
          onPlay={handlePlay}
          onPause={handlePause}
        >
          <source src={url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </Box>
    );
  }

  return (
    <Box m="10px 15px">
      <Box
        m="1px 0 0 0"
        height="79vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px",
            color: colors.bg[100],
            cursor: "pointer",

            // Change the border color of the right side of the cell
            borderBottom: "1px solid #f5f5f5",
          },

          "& .name-column--cell": {
            color: colors.bg[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.bg[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.bg[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={formattedData} columns={columnData} rowHeight={70} />
      </Box>
      {/* {selectedLead && (
       
          <DialogComponent
            open={openDialog}
            onClose={handleCloseDialog}
            leadInfo={selectedLead}
            staffs={staffResponseData}
            onUpdateStatus={handleUpdateStatus} // Pass the callback function
          />
       
      )} */}
    </Box>
  );
};
export default CallStatus;
