// CardItem.js
import React from "react";
import Card from "@mui/material/Card";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";
import { IconButton } from "@mui/material";

const NumberCard = ({ title, value, bgColor, textColor, icon, handleItemClick }) => {
  const boxStyle = {
    flex: "1", 
    margin: "0px",
    backgroundColor: "#ffffff",
    borderRadius: "1px",
  };
  return (
    <Card
      style={boxStyle}
      onClick={() => handleItemClick(title)}
      sx={{
        m: "5px",
        elevation: 0,
        borderRadius: "10px",
        transition: "background-color 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#0f0f0f",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
       
        <Typography
          display="flex"
          justifyContent="start"
          variant="h6"
          color={"#000000"}
          padding="10px"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
         
        >
          {title}
        </Typography>
        {icon && <IconButton sx={{ color: textColor }}>{}</IconButton>}

      </Box>

      <Typography
        display="flex"
        justifyContent="end"
        variant="h6"
        color="#000000"
        sx={{ m: "0 10px 10px 0" }}
      >
        {value}
      </Typography>
    </Card>
  );
};

export default NumberCard;
