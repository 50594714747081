import { Box } from "@mui/material";
import Header from "../../components/header";
import CallStatus from "./CallStatus";

const Dashboard = () => {
  return <Box>

    <Header 
    title={"Dashboard"}
    subTitle={"Manage all the data chart here"}
    />
    
   <CallStatus />
  </Box>;
};
export default Dashboard;
