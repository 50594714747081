import { Box, Button, Paper, TextField, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { UpdateNameApiCall } from "../data/repos/userRepo";

const NameEdit = ({studentIdNumber}) => {
  const [formValues, setFormValues] = useState({});
  const [nameUpdateResponse, setNameUpdateResponse] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const initialValues = {
    name: "",
  };
  const boxStyle = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px",
    maxHeight: "90vh",
    overflowY: "auto",
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const previewBoxStyle = {
    margin: "0px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
  };
  const innerBox = {
    flex: "1",
    margin: "15px",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const handleFormSubmit = (values) => {
    const updateRequestObj = {
      id: studentIdNumber,
      name: values.name
    };

    console.log("requestObj:", updateRequestObj);

    updateNameApi.mutate(updateRequestObj);
  };

  const updateNameApi = useMutation(UpdateNameApiCall, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setNameUpdateResponse(data?.responsedata);
      toast.success(data.responsedescription);
      setIsSuccess(true)
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  return (
    <Paper style={boxStyle}>
      <Box
        width="100%"
        height="100%"
        borderRadius="15px"
        style={previewBoxStyle}
      >
        <Box style={innerBox}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikBag) => {
              handleFormSubmit(values, formikBag);
            }}
            validator={() => ({})}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="15px"
                  rowGap="35px"
                  gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },

                    "& .MuiInputLabel-root": {
                      color: "#333333", // Set the hint text color to dark color
                    },
                    "& input": {
                      color: "#000000",
                      borderRadius: "15px",
                      height: "10px",
                    },

                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor: "#f6f6f6",
                      "& fieldset": {
                        borderColor: "transparent", // Remove border color
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent", // Remove border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent", // Remove border color on focus
                      },
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    onBlur={handleBlur}
                    InputLabelProps={{
                      sx: {
                        transform: "translate(0, -20px)",
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        name: e.target.value,
                      }));
                    }}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{
                      gridColumn: "span 2",
                    }}
                  />

                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled ={isSuccess}
                    sx={{
                      width: "90%",
                      borderRadius: "25px", // Set the border radius as needed
                      backgroundColor: "#000000", // Set the background color to black
                      color: "#ffffff", // Set the text color to white (optional)
                      "&:hover": {
                        backgroundColor: "#333333", // Change the background color on hover
                      },
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
};
export default NameEdit;
