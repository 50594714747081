import React from 'react';
import TextField from '@mui/material/TextField';

const DateSelection = ({ label, value, onChange, error, helperText, keyId, keyVal }) => {
  const handleDateChange = (event) => {
    // Extracting date value from event
    const selectedDate = event.target.value;
  // Split the date string by '-'
  const parts = selectedDate.split('-');
  // Rearrange the parts to form a date string in dd/mm/yyyy format
  const formattedStartDateString = parts[2] + '/' + parts[1] + '/' + parts[0];

  

    onChange(keyId,keyVal,"", formattedStartDateString);
  };
  return (
    <TextField
      fullWidth
      type="date"
      label={label}
      InputLabelProps={{
        sx: {
          transform: "translate(0, -25px)",
        },
      }}
      onChange={handleDateChange}
      value={value}
      error={error}
      helperText={helperText}
      sx={{
        gridColumn: "span 1",
      }}
    />
  );
};

export default DateSelection;
