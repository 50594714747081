import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  DateTimeFormatter,
  formatCustomDate,
  formatCustomTime,
} from "../utls/dateTimeUtls";

const TimeLine = ({ nestedComments }) => {
  const flattenComments = nestedComments.flatMap((innerArray) => innerArray);

  let lastDisplayedDate = null;

  return (
    <Timeline align="alternate">
      {flattenComments && flattenComments.length > 0 ? (
        flattenComments.map((item, index) => {
      
          const [formattedDate, formattedTime] = DateTimeFormatter(item.time);
          console.log("formated values:", formattedDate, formattedTime)
          

          const shouldDisplayDate = lastDisplayedDate !== formattedDate;

          if (shouldDisplayDate) {
            lastDisplayedDate = formattedDate;
          }

          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ flex: 0, minWidth: 100, height: "20px" }}
              >
                {shouldDisplayDate && (
                  <Typography variant="body2" color="#060606">
                    {formattedDate}
                  </Typography>
                )}
                <Typography variant="body2" color="#060606">
                  {formattedTime}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  color={shouldDisplayDate ? "primary" : "secondary"}
                />
                <TimelineConnector
                  sx={{
                    height: "0px",
                    backgroundColor: "#ccc",
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  variant="body2"
                  sx={{
                    my: "1px",
                    ml: "10px",
                    textAlign: "start",
                    width: "100%",
                    justifyContent: "start",
                    fontSize: "14px",
                  }}
                  color="#000000"
                >
                  {item.message}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })
      ) : (
        <Typography
          display="flex"
          justifyContent="start"
          variant="h5"
          fontWeight="bold"
          color="#000000"
          sx={{ m: "20px 0 0 20px" }}
        >
          No Comments yet!
        </Typography>
      )}
    </Timeline>
  );
};

export default TimeLine;
