import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Paper,
  Typography,
} from "@mui/material";
import Header from "../../components/header";
import { useEffect, useState } from "react";
import {
  ArrowLeft,
  ArrowRight,
  BloodtypeRounded,
  DateRangeOutlined,
  DateRangeRounded,
  EmailOutlined,
  EmailRounded,
  EventSeatRounded,
  NavigationRounded,
  PhoneIphoneRounded,
  PublicRounded,
} from "@mui/icons-material";

import AttandenceCard from "../../components/AttandenceCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const itemsPerPage = 15;

const AttandenceDashboard = () => {
  const [displayText, setDisplayText] = useState("Start");
  const [displayColor, setDisplayColor] = useState("#FFA500");
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [calendarDates, setCalendarDates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle clicking on the next button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle clicking on the previous button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(calendarDates.length / itemsPerPage);

  // Calculate the index range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, calendarDates.length);

  // Get the items to display on the current page
  const itemsOnCurrentPage = calendarDates.slice(startIndex, endIndex);

  const handleClick = () => {
    setDisplayText(displayText === "Start" ? "Stop" : "Start");
    setDisplayColor(displayColor === "#75E900" ? "#FFA500" : "#75E900");
    if (isTimerRunning) {
      // Stop the timer
      clearInterval(startTime);
      setIsTimerRunning(false);
    } else {
      // Start the timer
      const timer = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1000);
      }, 1000);
      setStartTime(timer);
      setIsTimerRunning(true);
    }
  };

  const attendanceData = [
    {
      id: 1,
      date: "01/04/2024",
      startedat: "09:10:67 AM",
      endedat: "06:00:00 PM",
      status: "On Time",
    },

    {
      id: 3,
      date: "30/03/2024",
      startedat: "09:10:67 AM",
      endedat: "06:00:00 PM",
      status: "On Time",
    },
    {
      id: 4,
      date: "29/03/2024",
      startedat: "09:00:67 AM",
      endedat: "06:30:45 PM",
      status: "On Time",
    },
    {
      id: 5,
      date: "28/03/2024",
      startedat: "09:10:67 AM",
      endedat: "06:00:00 PM",
      status: "Leave",
    },
    {
      id: 6,
      date: "27/03/2024",
      startedat: "09:00:67 AM",
      endedat: "06:30:45 PM",
      status: "On Time",
    },
    {
      id: 7,
      date: "26/03/2024",
      startedat: "09:10:67 AM",
      endedat: "06:00:00 PM",
      status: "On Time",
    },
    {
      id: 8,
      date: "25/03/2024",
      startedat: "09:00:67 AM",
      endedat: "06:30:45 PM",
      status: "On Time",
    },

    {
      id: 10,
      date: "23/03/2024",
      startedat: "09:00:67 AM",
      endedat: "06:30:45 PM",
      status: "Late",
    },
    {
      id: 11,
      date: "22/03/2024",
      startedat: "09:10:67 AM",
      endedat: "06:00:00 PM",
      status: "On Time",
    },
    {
      id: 12,
      date: "21/03/2024",
      startedat: "09:00:67 AM",
      endedat: "06:30:45 PM",
      status: "On Time",
    },
    {
      id: 13,
      date: "20/03/2024",
      startedat: "09:10:67 AM",
      endedat: "06:00:00 PM",
      status: "Late",
    },
    {
      id: 14,
      date: "19/03/2024",
      startedat: "09:00:67 AM",
      endedat: "06:30:45 PM",
      status: "On Time",
    },
  ];

  // Function to convert API response data into a map
  const convertDataToMap = (apiData) => {
    const dataMap = {};
    apiData.forEach((item) => {
      const [day, month, year] = item.date.split("/");
      const dateString = `${day}/${month}/${year}`;

      // Log dateString and item.date for debugging
      console.log("Date String:", dateString);
      console.log("Item Date:", item.date);

      // Check if the date string exists in the dataMap
      if (!dataMap[dateString]) {
        // If it doesn't exist, set the key-value pair in the dataMap

        dataMap[dateString] = item;
      }
    });

    return dataMap;
  };

  // Function to get calendar dates with data
  const getCalendarDatesWithData = async (providedDate) => {
    // const apiData = await fetchData();
    const dataMap = convertDataToMap(attendanceData);
    console.log("DataMap:", dataMap);
    const currentDate = new Date();

    const [day, month, year] = providedDate.split("/").map(Number);
    const providedDateObj = new Date(year, month - 1, day);
    console.log("providedDateObj:", providedDateObj);
    console.log("currentDate:", currentDate);

    const datesArray = [];
    while (providedDateObj <= currentDate) {
      const dateString = providedDateObj.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let data = dataMap[dateString];

      // If data is undefined and it's a Sunday, assign Week Off status
      if (!data) {
        if (providedDateObj.getDay() === 0) {
          // 0 represents Sunday
          data = {
            date: dateString,
            startedat: "-",
            endedat: "-",
            status: "Week Off",
          };
        } else {
          // Otherwise, keep data as null
          // data = null;
          data = {
            date: dateString,
            startedat: "-",
            endedat: "-",
            status: "Leave",
          };
        }
      }
      console.log("data:", data);
      datesArray.push({ date: dateString, data: data });
      providedDateObj.setDate(providedDateObj.getDate() + 1);
    }
    return datesArray.reverse();
  };

  useEffect(() => {
    const fetchCalendarDates = async () => {
      const providedDate = "20/02/2024"; // Example provided date (DD/MM/YYYY format)
      const datesWithData = await getCalendarDatesWithData(providedDate);
      setCalendarDates(datesWithData);
    };
    fetchCalendarDates();
  }, []);

  return (
    <Box>
      <Box display="flex" width="100%" height="100%">
        <Box sx={{ height: "100%" }}>
          <Header title={"My Dashboard"} subTitle={"My Attandance Dashboard"} />
          <Box style={{ backgroundColor: "#ffffff", height: "83vh" }}>
            <Box
              style={{
                height: "30vh",
                display: "flex",
                justifyContent: "space-around",
                marginTop: "10px",
              }}
            >
              <Paper
                sx={{
                  width: "25%",
                  borderRadius: "15px",
                  margin: "10px",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column", // Align children vertically
                  alignItems: "center", // Align children horizontally
                }}
                elevation="3"
              >
                <div
                  style={{
                    width: "120px",
                    height: "120px",
                    position: "relative", // Position the radial gradient container
                    overflow: "hidden", // Hide overflowing gradient
                    margin: "10px",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                >
                  <div
                    style={{
                      position: "absolute", // Position the gradient
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%", // Make it a circle
                      background:
                        "radial-gradient(circle, rgba(255,255,255,0) 60%, rgba(0,0,0,0.75) 100%)",
                    }}
                  ></div>
                  <Box
                    sx={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "60px", // Make it a circle
                      backgroundColor: displayColor,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer", // Change cursor to pointer when hovering
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontVariant: "h4",
                        fontSize: "16px",
                      }}
                    >
                      {displayText}
                    </Typography>
                  </Box>
                </div>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row", // Align children vertically
                    justifyContent: "space-around",
                    alignItems: "center", // Align children horizontally
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography sx={{ color: "black" }}>Started on</Typography>
                    <Typography sx={{ color: "black" }}>00:00:00 AM</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ color: "black" }}>End By</Typography>
                    <Typography sx={{ color: "black" }}>00:00:00 AM</Typography>
                  </Box>
                </Box>
              </Paper>

              <Paper
                elevation="3"
                sx={{
                  width: "50%",
                  borderRadius: "15px",
                  margin: "10px",
                  backgroundColor: "white",

                  padding: "15px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", my: "10px" }}>
                  <Avatar sx={{ bgcolor: "#000000", color: "#ffffff" }}>
                    {"M"}
                  </Avatar>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                      color="#000000"
                    >
                      {"Muhammed Riyas"}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"Super Admin"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:"center"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "5px",
                      my: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <EmailRounded sx={{ mr: "3px", color: "#37474F" }} />
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "start",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                        color="#000000"
                      >
                        {"Email"}
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"riyas@teqbae.com"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "5px",
                      my: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PhoneIphoneRounded sx={{ mr: "3px", color: "black" }} />
                      {/* Icon before text */}
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "start",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                        color="#000000"
                      >
                        {"Mobile Number"}
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"9898456785"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "5px",
                      my: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PublicRounded sx={{ mr: "3px", color: "black" }} />
                      {/* Icon before text */}
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "start",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                        color="#000000"
                      >
                        {"Nationality"}
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"Indian"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "5px",
                      my: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <DateRangeRounded sx={{ mr: "3px", color: "black" }} />
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "start",
                          width: "100%",
                          justifyContent: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                        color="#000000"
                      >
                        {"Doj"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"12/02/2022"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "5px",
                      my: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <EventSeatRounded sx={{ mr: "3px", color: "black" }} />
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "start",
                          width: "100%",
                          justifyContent: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                        color="#000000"
                      >
                        {"Department"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"Sales"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "5px",
                      my: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <BloodtypeRounded sx={{ mr: "3px", color: "black" }} />
                      {/* Icon before text */}
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "start",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                        color="#000000"
                      >
                        {"Blood Group"}
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"O+"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "5px",
                      my: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <DateRangeRounded sx={{ mr: "3px", color: "black" }} />
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "start",
                          width: "100%",
                          justifyContent: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                        color="#000000"
                      >
                        {"Dob"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"10/10/1990"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "5px",
                      my: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <DateRangeRounded sx={{ mr: "3px", color: "black" }} />
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "start",
                          width: "100%",
                          justifyContent: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                        color="#000000"
                      >
                        {"Dob"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        width: "100%",
                        justifyContent: "center",
                        fontSize: "12px",
                        color: "#666666",
                      }}
                    >
                      {"10/10/1990"}
                    </Typography>
                  </Box>
                </Box>
              </Paper>

              <Paper
                elevation="3"
                sx={{
                  width: "25%",
                  borderRadius: "15px",
                  margin: "10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                start/stop
              </Paper>
            </Box>
            {/* Attandance details daily */}
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "10px",
              }}
            >
              <Paper
                elevation="3"
                sx={{
                  width: "100%",
                  borderRadius: "15px",
                  margin: "10px",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                <Grid2 container spacing={1}>
                  {itemsOnCurrentPage.map((item, index) => (
                    <Grid2 item key={index} xs={12} sm={6} md={2.4} lg={2.4}>
                      <AttandenceCard item={item} />
                    </Grid2>
                  ))}
                </Grid2>
              </Paper>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                margin: "10px",
              }}
            >
              <ButtonGroup>
                <Button
                  onClick={handlePrevious}
                  startIcon={<ArrowLeft />}
                  variant="outlined"
                  shape="rounded"
                >
                  Previous
                </Button>
                {[...Array(totalPages)].map((_, index) => (
                  <Button
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    variant={
                      currentPage === index + 1 ? "outlined" : "outlined"
                    }
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  onClick={handleNext}
                  endIcon={<ArrowRight />}
                  variant="outlined"
                  shape="rounded"
                >
                  Next
                </Button>
              </ButtonGroup>
            </div>
          </Box>
        </Box>
    
      </Box>
    </Box>
  );
};
export default AttandenceDashboard;
