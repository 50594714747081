import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
  BloodtypeOutlined,
  BusinessCenterOutlined,
  BusinessOutlined,
  DateRange,
  DateRangeOutlined,
  PeopleOutlineRounded,
  Person2Outlined,
  VerifiedUserRounded,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { EditRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { tokens } from "./theme";

const ProfileCard = ({ profile, onEditClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const box2Style = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "5px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    maxHeight: "95vh",
    overflowY: "auto",
    borderRadius: "15px",
  };

  return (
    <Paper style={box2Style}>
      <Box
        sx={{ gridColumn: "span 1" }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box sx={{ m: "0 0 0 15px" }} display="flex" alignItems="center">
          <IconButton sx={{ color: colors.bg[100] }}>
            <PeopleOutlineRounded />
          </IconButton>
          <Typography
            display="flex"
            justifyContent="start"
            variant="h5"
            fontWeight="bold"
            color={colors.bg[100]}
            sx={{ m: "0 0 0 0" }}
          >
            Student Profile
          </Typography>
        </Box>
        <IconButton onClick={onEditClick} sx={{ color: colors.bg[100],  m: "0 10px 0 0" }}>
          <EditRounded />
        </IconButton>
      </Box>
      <Box width="100%" height="100%" borderRadius="15px">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          padding="15px"
        >
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <EmailOutlinedIcon />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                12th Mark
              </Typography>
            </Box>

            <Typography
              display="flex"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.twelthmark}
            </Typography>
          </div>

          <div style={{ flex: 1, m: "10px" }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <PhoneIphoneOutlinedIcon />
              </IconButton>
              <Typography
                display="flex"
                justifyContent="start"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                12th Board
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              { profile.twelthboard}
            </Typography>
          </div>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          padding="15px"
        >
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <WorkOutlineOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                12th Pass Year
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.twelthpassyear}
            </Typography>
          </div>
          <div style={{ flex: 1, m: "10px" }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BusinessOutlined />
              </IconButton>
              <Typography
                display="flex"
                justifyContent="start"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                12th English Mark
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.twelthengmark}
            </Typography>
          </div>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          padding="15px"
        >
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BusinessOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                UG Course
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.ugcourse}
            </Typography>
          </div>

          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BusinessCenterOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                UG University
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.uguniversity}
            </Typography>
          </div>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          padding="15px"
        >
          <div style={{ flex: 1, m: "10px" }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <DateRangeOutlined />
              </IconButton>
              <Typography
                display="flex"
                justifyContent="start"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                UG Passout Year
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.ugpassyear}
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <DateRange />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                UG Mark
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.ugmark}
            </Typography>
          </div>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          padding="15px"
        >
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BloodtypeOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                PG Course Name
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.pgcourse}
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BloodtypeOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                PG Mark
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.pgmark}
            </Typography>
          </div>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          padding="15px"
        >
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BloodtypeOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                PG University
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.pguniversity}
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BloodtypeOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                PG Passout Year
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.pgpassyear}
            </Typography>
          </div>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          padding="15px"
        >
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BloodtypeOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
               English Proficiancy
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.englishprof}
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BloodtypeOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                English Proficiancy Score
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.score}
            </Typography>
          </div>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          padding="15px"
        >
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BloodtypeOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
              Occupation
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.occupation}
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              <IconButton sx={{ color: colors.textcolor[100] }}>
                <BloodtypeOutlined />
              </IconButton>
              <Typography
                display="flex"
                variant="h6"
                color={colors.textcolor[100]}
                sx={{ m: "0 0 0 0" }}
              >
                Salary
              </Typography>
            </Box>
            <Typography
              display="flex"
              justifyContent="start"
              variant="h6"
              paddingLeft="8px"
              color={colors.bg[100]}
              sx={{ m: "0 0 0 0" }}
            >
              {profile.salary}
            </Typography>
          </div>
        </Box>
      </Box>
    </Paper>
  );
};

export default ProfileCard;



