// src/redux/leadsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const leadsSlice = createSlice({
  name: 'leads',
  initialState: {
    leads: [],
    rescheduledLeadsData : [],
    selectedLead: null,
    searchQuery: '',
    sortingOrder: 'desc',
    tabIndex: 0,
    currentPage: 1,
  },
  reducers: {
    setLeads(state, action) {
      state.leads = action.payload;
    },
    setRescheduledLeadsData(state, action){
      state.rescheduledLeadsData = action.payload;
    },
    setSelectedLead(state, action) {
      state.selectedLead = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setSortingOrder(state, action) {
      state.sortingOrder = action.payload;
    },
    setTabIndex(state, action) {
      state.tabIndex = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export const {
  setLeads,
  setSelectedLead,
  setSearchQuery,
  setSortingOrder,
  setTabIndex,
  setCurrentPage,
} = leadsSlice.actions;

export default leadsSlice.reducer;
