import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "./theme";

const Header = ({ title, subTitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={{ p: "20px" }}>
      <Typography
        variant="h4"
        color={colors.bg[100]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      
      <Typography 
      variant="h6" 
      color={colors.textcolor[100]}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};

export default Header;
