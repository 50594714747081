import { format } from "date-fns";

export function dateConvertor(date) {
  const apiDate = date; //"Mon Dec 11 10:22:09 UTC 2023";

  // Parse the API date string to a Date object
  const parsedDate = new Date(apiDate);

  // Format the date using date-fns
  const formattedDate = format(parsedDate, "dd/MM/yyyy h:mm a");

  console.log(formattedDate); // Output: 11/12/2023 10:22 AM
  return formattedDate;
}

export const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

export const formatCallStatus = (callStatus) => {
  if (callStatus.Valid) {
    return callStatus.String;
  } else {
    return "Not Called";
  }
};

export const formatStaffName = (staff) => {
  if (staff.Valid) {
    return staff.String;
  } else {
    return "Unassigned";
  }
};

export const formatStudentName = (student) => {
  if (student.Valid) {
    return student.String;
  } else {
    return "Nil";
  }
};

export const formatCallerNumber = (item) => {
  if (item.calltype === "Incoming") {
    return item.caller_number;
  } else {
    return item.called_number;
  }
};

export function secondsToHms(d) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h < 10 ? "0" + h : h;
  const mDisplay = m < 10 ? "0" + m : m;
  const sDisplay = s < 10 ? "0" + s : s;

  return hDisplay + ":" + mDisplay + ":" + sDisplay;
}

export const formatedCountry = (countryObj) => {
  if (countryObj.Valid) {
    return countryObj.String;
  }
};

export const formatedFollowupCount = (followupcount) => {
  if (followupcount.Valid) {
    return followupcount.String;
  } else {
    return "0";
  }
};

export const formatDesignation = (designation) => {
  if (designation.Valid) {
    return designation.String;
  } else {
    return "Unassigned";
  }
};
