// CardItem.js
import React from "react";
import Card from "@mui/material/Card";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";
import { IconButton } from "@mui/material";

const FactCard = ({ title, value, bgColor, textColor, icon, onCardClick }) => {
  const boxStyle = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px",

    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: bgColor,
    borderRadius: "15px",
  };
  return (
    <Card
      style={boxStyle}
      sx={{
        m: "5px",
        elevation: 0,
        borderRadius: "15px",
        transition: "background-color 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
      }}
      onClick={onCardClick}
    >
      <Box display="flex" alignItems="center">
        {icon && <IconButton sx={{ color: textColor }}>{icon}</IconButton>}

        <Typography
          display="flex"
          justifyContent="start"
          variant="h6"
          color={textColor}
          sx={{ m: "0 0 0 0" }}
        >
          {title}
        </Typography>
      </Box>

      <Typography
        display="flex"
        justifyContent="end"
        variant="h6"
        color="#000000"
        sx={{ m: "0 10px 10px 0" }}
      >
        {value}
      </Typography>
    </Card>
  );
};

export default FactCard;
