import {
  Box,
  Chip,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Toaster } from "react-hot-toast";
import {
  FilterListRounded,
  SortRounded,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import {
  GetLeadsByUser,
  GetNewLeads,
  GetRedirectedLeads,
  GetRescheduledLeads,
  GetTodaysLeads,
} from "../../data/repos/userRepo";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ScheduledCard from "../../components/scheduledCard";
import TaskCard from "../../components/TaskCard";

const ITEMS_PER_PAGE = 15;

const ManageTask = ({ userId }) => {
  const [leadsResponseData, setLeadsResponseData] = useState([]);
  const [rescheduledLeadsData, setRescheduledLeadsData] = useState([]);
  const [todaysLeads, setTodaysLeads] = useState([]);
  const [redirectedLeads, setRedirectedLeads] = useState([]);

  const [newLeadsData, setNewLeadsData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [sortingOrder, setSortingOrder] = useState("desc");

  const [newSearchQuery, setNewSearchQuery] = useState("");

  const [selectedDataTiltle, setSelectedDataTiltle] = useState("");

  const navigate = useNavigate();

  const boxStyle = {
    flex: "2.5",
    margin: "10px",
    height: "75vh",
    overflowY: "auto",
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const numberBoxStyle = {
    flex: "1",
    margin: "10px",
    overflowY: "auto",
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const { newLeads, isNewLeadsLoading, isNewLeadsError } = useQuery(
    "newleads",
    GetNewLeads,
    {
      onSuccess: (newLeads, variables, context) => {
        setNewLeadsData(newLeads?.responsedata);
        console.log("newLeadsData:", newLeadsData);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const {
    rescheduledleads,
    isrescheduledleadsLoading,
    isrescheduledleadsError,
  } = useQuery("rescheduledleads", GetRescheduledLeads, {
    onSuccess: (rescheduledleads, variables, context) => {
      setRescheduledLeadsData(rescheduledleads?.responsedata);
      console.log("rescheduledleads:", rescheduledLeadsData);
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
    },
  });

  const {
    todaysuncalledleads,
    istodaysuncalledleadsLoading,
    istodaysuncalledleadsError,
  } = useQuery("todaysleads", GetTodaysLeads, {
    onSuccess: (todaysuncalledleads, variables, context) => {
      setTodaysLeads(todaysuncalledleads?.responsedata);
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
    },
  });

  const { redirectedleads, isredirectedleadsLoading, isredirectedleadsError } =
    useQuery("redirectedleads", GetRedirectedLeads, {
      onSuccess: (redirectedleads, variables, context) => {
        setRedirectedLeads(redirectedleads?.responsedata);
        console.error("redirectedLeads:", redirectedleads?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    });

  const { leads, isLeadsLoading, isLeadsError } = useQuery(
    "leads",
    GetLeadsByUser,
    {
      onSuccess: (leads, variables, context) => {
        setLeadsResponseData(leads?.responsedata);
        console.log("leads:", leadsResponseData);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const handleItemClick = (item) => {
    navigate(`/u/${userId}/task/lead/${item.id}`);
  };

  const filteredNewLeadsData = newLeadsData.filter(
    (item) =>
      item.mobile.includes(newSearchQuery) ||
      item.name.toLowerCase().includes(newSearchQuery.toLowerCase())
  );

  const filteredSelectedData = selectedData.filter(
    (item) =>
      item.mobile.includes(newSearchQuery) ||
      item.name.toLowerCase().includes(newSearchQuery.toLowerCase())
  );

  const filteredScheduledLeads = rescheduledLeadsData.filter(
    (item) =>
      item.mobile.includes(newSearchQuery) ||
      item.name.toLowerCase().includes(newSearchQuery.toLowerCase())
  );

  const filteredRedirectedLeadsData = redirectedLeads.filter(
    (item) =>
      item.mobile.includes(newSearchQuery) ||
      item.name.toLowerCase().includes(newSearchQuery.toLowerCase())
  );

  const filteredTodaysLeadsData = todaysLeads.filter(
    (item) =>
      item.mobile.includes(newSearchQuery) ||
      item.name.toLowerCase().includes(newSearchQuery.toLowerCase())
  );

  const totalFilteredLeads = leadsResponseData.filter(
    (item) =>
      item.mobile.includes(newSearchQuery) ||
      item.name.toLowerCase().includes(newSearchQuery.toLowerCase())
  );

  useEffect(() => {
    showTotalLeads();
  }, [leadsResponseData]); // Dependency array containing leadsResponseData

  const showScheduledCalls = () => {
    setSelectedDataTiltle("Scheduled Calls");
    setSelectedData(filteredScheduledLeads);
  };
  const showNewLeads = () => {
    setSelectedDataTiltle("New Leads");
    setSelectedData(filteredNewLeadsData);
  };
  const showTotalLeads = () => {
    console.log("total Leads:", totalFilteredLeads);
    setSelectedDataTiltle("Total Leads");
    setSelectedData(totalFilteredLeads);
  };
  const showTodaysLeads = () => {
    setSelectedDataTiltle("Leads Assigned Today");
    setSelectedData(filteredTodaysLeadsData);
  };
  const showGiveUpLeads = () => {
    setSelectedDataTiltle("Redirected Leads");
    setSelectedData(filteredRedirectedLeadsData);
  };
  const [value, setValue] = useState(0);

  // Function to fetch and set data based on selected tab index
  const totalLeadsController = (tabIndex) => {
    // Fetch data based on tabIndex
    switch (tabIndex) {
      case 0:
        showTotalLeads();
        break;
      case 1:
        showNewLeads();
        break;
      case 2:
        showScheduledCalls();
        break;
      case 3:
        showTodaysLeads();
        break;
      case 4:
        showGiveUpLeads();
        break;
      default:
        showTotalLeads(); // Handle default case
        break;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    totalLeadsController(newValue);
  };

  const formatScheduledDateTime = (rescheduledate, rescheduletime) => {
    // Split the date and time strings
    const [year, month, day] = rescheduledate.split("-");
    const [hours, minutes] = rescheduletime.split(":");

    // Format the date and time
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}`;

    // Return the formatted date and time string
    return `${formattedDate} ${formattedTime};`
  };

  const handleFilterButtonClick = () => {
    const newSortingOrder = sortingOrder === "desc" ? "asc" : "desc";
    setSortingOrder(newSortingOrder);

    if (value === 2) {
      // Sort the list based on the assignedon date
      const sortedItems = [...selectedData].sort((a, b) => {
        // Parse the assignedon dates
        const assignedonDateA = parseScheduledDate(
          formatScheduledDateTime(
            a.updates.rescheduledate,
            a.updates.rescheduletime
          )
        );
        const assignedonDateB = parseScheduledDate(
          formatScheduledDateTime(
            b.updates.rescheduledate,
            b.updates.rescheduletime
          )
        );

         if (newSortingOrder === "desc") {
          // Compare the assignedon dates
          if (assignedonDateA > assignedonDateB) return -1;
          if (assignedonDateA < assignedonDateB) return 1;
          return 0;
        } else {
          // Compare the assignedon dates
          if (assignedonDateA < assignedonDateB) return -1;
          if (assignedonDateA > assignedonDateB) return 1;
          return 0;
        }
      });

      // Update the state with the sorted items
      setSelectedData(sortedItems);
    } else {
      // Sort the list based on the assignedon date
      const sortedItems = [...selectedData].sort((a, b) => {
        // Parse the assignedon dates
        const assignedonDateA = parseDate(a.assignedon);
        const assignedonDateB = parseDate(b.assignedon);

        // Compare the assignedon dates based on sorting order
        if (newSortingOrder === "desc") {
          // Compare the assignedon dates
          if (assignedonDateA > assignedonDateB) return -1;
          if (assignedonDateA < assignedonDateB) return 1;
          return 0;
        } else {
          // Compare the assignedon dates
          if (assignedonDateA < assignedonDateB) return -1;
          if (assignedonDateA > assignedonDateB) return 1;
          return 0;
        }
      });

      // Update the state with the sorted items
      setSelectedData(sortedItems);
    }
  };

  const parseDate = (dateString) => {
    // Split the date and time parts
    const [datePart, timePart] = dateString.split(" ");

    // Split the date into day, month, and year
    const [day, month, year] = datePart.split("/");

    // Split the time into hours, minutes, and seconds
    const [hours, minutes, seconds] = timePart.split(":");
    console.log(
      "parsed Date:",
      new Date(year, month - 1, day, hours, minutes, seconds)
    );
    // Construct the date object
    // Note: Months are zero-based in JavaScript Date object, so subtract 1 from the month

    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const parseScheduledDate = (dateString) => {
    // Split the date and time parts
    const [datePart, timePart] = dateString.split(" ");

    // Split the date into day, month, and year
    const [day, month, year] = datePart.split("/");

    // Split the time into hours and minutes
    const [hours, minutes] = timePart.split(":");

    // Construct the formatted date string
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
  };

  //pagination

  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  return (
    <div>
      <Box m="0px 1px" width="100%" height="80vh">
        <Paper elevation={3} style={boxStyle}>
          <Box
            width="100%"
            height="100%"
            justifyContent="space-between"
            borderRadius="15px"
          >
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth" // Ensure all tabs are visible
                textColor="primary" // Keep the text color consistent
                indicatorColor="primary" // Set indicator color
                aria-label="Task Tabs"
                sx={{
                  "& .MuiTab-root": {
                    color: "#ffffff", // Set default text color for tabs
                    backgroundColor: "#000000",
                  },
                  "& .Mui-selected": {
                    color: "#75E900 !important", // Set text color for selected tab
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#75E900 !important", // Set indicator color
                  },
                  "& .MuiTab-wrapper": {
                    display: "flex", // Make the wrapper a flex container
                    alignItems: "center", // Align items vertically
                    justifyContent: "center", // Center content horizontally
                    width: "auto",
                  },
                }}
              >
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <span>Total Assigned Leads</span>
                      <Chip
                        size="small"
                        variant="filled"
                        color={value === 0 ? "primary" : "default"}
                        label={leadsResponseData.length}
                        sx={{ marginLeft: 1 }} // Adjust spacing between text and chip
                      />
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <span>Pending Leads</span>
                      <Chip
                        size="small"
                        variant="filled"
                        color={value === 1 ? "primary" : "default"}
                        label={newLeadsData.length}
                        sx={{ marginLeft: 1 }} // Adjust spacing between text and chip
                      />
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <span>Scheduled Calls</span>
                      <Chip
                        size="small"
                        variant="filled"
                        color={value === 2 ? "primary" : "default"}
                        label={rescheduledLeadsData.length}
                        sx={{ marginLeft: 1 }} // Adjust spacing between text and chip
                      />
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <span>Today's Lead</span>
                      <Chip
                        size="small"
                        variant="filled"
                        color={value === 3 ? "primary" : "default"}
                        label={todaysLeads.length}
                        sx={{ marginLeft: 1 }} // Adjust spacing between text and chip
                      />
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <span>Redirected Lead</span>
                      <Chip
                        size="small"
                        variant="filled"
                        color={value === 4 ? "primary" : "default"}
                        label={redirectedLeads.length}
                        sx={{ marginLeft: 1 }} // Adjust spacing between text and chip
                      />
                    </Box>
                  }
                />
              </Tabs>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              sx={{
                "& > div": {
                  marginTop: "10px",
                },
                marginX: "5px",
              }}
            >
              <TextField
                fullWidth
                type="text"
                placeholder="Search"
                onChange={(e) => setNewSearchQuery(e.target.value)}
                value={newSearchQuery}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#333333", // Set the hint text color to dark color
                  },
                  "& input": {
                    color: "#000000",
                    borderRadius: "15px",
                    height: "10px",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    backgroundColor: "#f6f6f6",
                    "& fieldset": {
                      borderColor: "transparent", // Remove border color
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent", // Remove border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent", // Remove border color on focus
                    },
                  },
                }}
              />
              <IconButton
                onClick={handleFilterButtonClick}
                style={{ color: "#000000" }}
              >
                {sortingOrder === "desc" ? (
                  <FilterListRounded />
                ) : (
                  <SortRounded />
                )}
              </IconButton>
            </Box>

            <Grid2 sx={{ p: "10px" }} container spacing={1}>
              {filteredSelectedData
                .slice(startIndex, endIndex)
                .map((item, index) => (
                  <Grid2 item key={index} xs={12} sm={6} md={2.4} lg={2.4}>
                    {value === 2 ? (
                      // Render ScheduledCard when the selected tab index is 2
                      <ScheduledCard
                        item={item}
                        handleItemClick={handleItemClick}
                      />
                    ) : (
                      // Render TaskCard for all other tab indices
                      <TaskCard item={item} handleItemClick={handleItemClick} />
                    )}
                  </Grid2>
                ))}
            </Grid2>
          </Box>
        </Paper>
        <div>
          {currentPage > 1 && (
            <button onClick={handlePrevPage}>Previous Page</button>
          )}
          {filteredSelectedData.length > endIndex && (
            <button onClick={handleNextPage}>Next Page</button>
          )}
        </div>
      </Box>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
};

export default ManageTask;