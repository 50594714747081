import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

const AttandenceCard = ({ item }) => {
  const currentDate = new Date();

  let eventDate = item.date;
  const currentDateFormatted = currentDate.toLocaleDateString("en-GB");
  if (eventDate === currentDateFormatted) {
    eventDate = "Today";
  } else {
    const [day, month, year] = eventDate.split("/").map(Number);
    const eventDateObj = new Date(year, month - 1, day);
    const formattedDate = `${day.toString().padStart(2, '0')} ${new Intl.DateTimeFormat('en', { month: 'short' }).format(eventDateObj)} ${year}`;
    eventDate = formattedDate;
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "On Time":
        return "#C8E6C9";
      case "Late":
        return "#FFE0B2";
      case "Leave":
        return "#FFCDD2";
      case "Week Off":
        return "#B3E5FC";
      default:
        return "rgba(0, 0, 0, 0.5)";
    }
  };

  const getStatusTextColor = (status) => {
    switch (status) {
      case "On Time":
        return "#1B5E20";
      case "Late":
        return "#E65100";
      case "Leave":
        return "#B71C1C";
      case "Week Off":
        return "#01579B";
      default:
        return "rgba(0, 0, 0, 0.5)";
    }
  };

  const statusColor = getStatusColor(item.data?.status);
  const textColor = getStatusTextColor(item.data?.status);

  return (
    <Card
      sx={{
        margin: "1px",
        backgroundColor: "#ffffff",
        borderRadius: "15px",
        transition: "background-color 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          padding: "5px !important",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", mb: "10px" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textAlign: "start",
                fontSize: "12px",
                fontWeight: 500,
                marginLeft: "10px",
                whiteSpace: "nowrap",
              }}
              color="#000000"
            >
              {eventDate}
            </Typography>

            {item.data?.status && (
              <Chip
                label={item.data.status}
                style={{ backgroundColor: statusColor, color: textColor }}
                size="small"
              />
            )}
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", mx: "10px" }}>
            <Typography
              variant="body2"
              sx={{
                mb: "5px",
                textAlign: "start",
                width: "100%",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
              color="#000000"
            >
              {"Start Time"}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                textAlign: "start",
                width: "100%",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#666666",
              }}
            >
              {item.data?.startedat}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", mx: "10px" }}>
            <Typography
              variant="body2"
              sx={{
                mb: "5px",
                textAlign: "start",
                width: "100%",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
              color="#000000"
            >
              {"End Time"}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                textAlign: "start",
                width: "100%",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#666666",
              }}
            >
              {item.data?.endedat}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AttandenceCard;
