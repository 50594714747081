import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../components/header";
import { tokens } from "../../components/theme";
import { DataGrid } from "@mui/x-data-grid";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";

import { QueryClient, useMutation, useQuery } from "react-query";

import {
  GetAllUsers,
  GetStaffs,
  UpdateLeadData,
} from "../../data/repos/userRepo";
import { useState } from "react";
import { formatDate } from "../../utls/commons";

import DialogComponent from "../../components/dialogComponent";
import TabComponent from "./tabComponent";
import ManageTask from "./manageTask";

const Job =  ({ userId }) => {

  return (
    <Box m="10px 20px">
      <Header title={"Task Dashboard"} subTitle={"View and manage all your daily task here"} />
    <ManageTask  userId={userId}/>
    
    </Box>
  );
};
export default Job;
