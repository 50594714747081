import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ reportResponse }) => {
  // State to hold chart data
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Function to extract data for the pie chart
    const extractChartData = () => {
      if (reportResponse) {
        const labels = Object.keys(reportResponse).filter(label => reportResponse[label] > 0);
        const data = labels.map(label => reportResponse[label]);
        const backgroundColors = generateRandomColors(labels.length);

        return {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColors,
            },
          ],
        };
      }
      return null;
    };

    // Generate random background colors for the pie chart
    const generateRandomColors = (numColors) => {
      const colors = [];
      for (let i = 0; i < numColors; i++) {
        colors.push(
          `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
            Math.random() * 256
          )}, ${Math.floor(Math.random() * 256)}, 0.6)`
        );
      }
      return colors;
    };

    // Set chart data
    setChartData(extractChartData());
  }, [reportResponse]);

  return (
    <div style={{ width: "500px", height: "500px" }}>
      {chartData && (
        <Pie
          data={chartData}
          options={{
            plugins: {
              legend: {
                position: "bottom",
                
                labels: {
                    boxWidth: 20, // Adjust the width of the legend items
                    padding: 15,
                  },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default PieChart;
